import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "posts masonry" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_post_tile = _resolveComponent("post-tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.posts && _ctx.posts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
            return (_openBlock(), _createBlock(_component_post_tile, {
              key: post.id,
              post: post
            }, null, 8, ["post"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "No posts found"))
  ]))
}