
import { defineComponent } from "vue";
import { getBlogPostsByTag, checkAndAddAltTags } from "@/utils/blog";
import Posts from "@/components/Blog/Posts.vue";
import Loader from "@/components/common/Loader.vue";

export default defineComponent({
  components: {
    Posts,
    Loader,
  },

  data() {
    return {
      isLoading: true,
      posts: [],
    };
  },

  methods: {
    fetchPosts(tag: string) {
      this.isLoading = true;
      getBlogPostsByTag(tag).then((posts) => {
        this.posts = posts;
        checkAndAddAltTags();
        this.isLoading = false;
      });
    },
  },

  computed: {
    routeTag() {
      return this.$route.params.tag;
    },
  },

  watch: {
    routeTag(newValue) {
      this.fetchPosts(newValue);
    },
  },

  created() {
    let { tag } = this.$route.params;
    if (Array.isArray(tag)) tag = tag.join(",");

    this.fetchPosts(tag);
  },
});
